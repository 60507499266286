import React from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { normalize } from "styled-normalize"

import BackgroundImage from "./background-image"
import Header from "./header"
import Footer from "./footer"

import { theme } from "../theme"

import "@fontsource/quicksand"

const GlobalStyle = createGlobalStyle`
	${normalize}

	* {
		box-sizing: border-box;
	}
	*::selection {
		background: rgba(${props => props.theme.color.primaryRGB}, 0.5);
	}
	body {
		font: 400 1rem Quicksand, sans-serif; 
		color: ${props => props.theme.color.text}
	}
`

const Layout = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle/>
                <BackgroundImage />
                <Header />
                {children}
                <Footer />
            </>
        </ThemeProvider>
    )
}

export default Layout
