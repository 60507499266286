import React, { useContext } from "react"
import styled from "styled-components"
import BlockquoteIcon from "./blockquote-icon"
import ActiveTestimonialContext from "../context/ActiveTestimonialContext"

const StyledTestimonial = styled.div`
    position: relative;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 2rem;

    &:not(:first-of-type) {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    &[data-active] {
        pointer-events: auto;
        opacity: 1;
        transition-delay: 0.25s;
        z-index: 2;
    }
    // content
    .testimonial-quote {
        font-style: italic;
    }
    .testimonial-author {
        // color: ${props => props.theme.color.textLight};
        text-align: center;
        padding-top: 1rem;
    }
    // border effect
    border: 0;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 2px
        rgba(${props => props.theme.color.primaryRGB}, 0.35);

    &:before,
    &:after {
        box-sizing: inherit;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid transparent;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
    }
    &[data-active]:before {
        border-top-color: ${props => props.theme.color.primary};
        border-right-color: ${props => props.theme.color.primary};
        animation: active-testimonial-top-right 12s forwards;
    }
    &[data-active]:after {
        border-bottom-color: ${props => props.theme.color.primary};
        border-left-color: ${props => props.theme.color.primary};
        animation: active-testimonial-bottom-left 12s forwards;
    }

    img {
        width: 100%;
    }

    @keyframes active-testimonial-bottom-left {
        0% {
            width: 0%;
            height: 0%;
            border-bottom-color: transparent;
        }
        49% {
            border-bottom-color: transparent;
        }
        50% {
            width: 0%;
            height: 100%;
            border-bottom-color: ${props => props.theme.color.primary};
        }
        100% {
            width: 100%;
            height: 100%;
        }
    }
    @keyframes active-testimonial-top-right {
        0% {
            width: 0%;
            height: 0%;
        }
        50% {
            height: 0%;
            width: 100%;
        }
        100% {
            height: 100%;
            width: 100%;
        }
    }
`

const Testimonial = props => {
    const { index, quote, author } = props
    const { activeSlide } = useContext(ActiveTestimonialContext)

    return (
        <StyledTestimonial
            data-testimonial={index}
            data-active={index === activeSlide ? "" : null}
        >
            <BlockquoteIcon />
            <div className="testimonial-quote">{quote}</div>
            <div className="testimonial-author">- {author}</div>
        </StyledTestimonial>
    )
}

export default Testimonial
