import React, { useRef } from "react"
import styled from "styled-components"
import emailjs from "emailjs-com"

import FormField from "./form-field"
import FormSubmit from "./form-submit"

const StyledContactForm = styled.form`
    margin: 0;
    max-width: 650px;
    margin: 0 auto;
`

const ContactForm = () => {
	const form = useRef();

    const sendEmail = e => {
        e.preventDefault()

        emailjs
            .sendForm(
                "service_6vwhg9t",
                "template_5nze66m",
                form.current,
                "6FQlMah8JrrwS9_ZP"
            )
            .then(
                result => {
                    console.log(result.text)
                },
                error => {
                    console.log(error.text)
                }
            )
    } // sendEmail()

    return (
        <StyledContactForm autoComplete="off" ref={form} onSubmit={sendEmail}>
            <FormField name="name" label="Name" fieldType="text" />
            <FormField name="email" label="Email" fieldType="text" />
            <FormField name="phone" label="Phone" fieldType="text" />
            <FormField name="message" label="Message" fieldType="textarea" />
            <FormSubmit text="Submit" />
        </StyledContactForm>
    )
}

export default ContactForm
