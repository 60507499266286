import React from "react"
import styled from "styled-components"

import Wrapper from "./wrapper"
import Divider from "./divider"

const StyledFooter = styled.footer`
    padding: 1rem 1rem 2rem;
    background: ${props => props.theme.color.white};
    color: ${props => props.theme.color.text};
    font-size: 0.825rem;

    .footer-layout {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer-copyright {
    }
    .footer-links {
        padding-left: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .footer-link {
            margin-left: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            padding: 0.25rem;
            border-radius: 50%;
            // border: 2px solid ${props => props.theme.color.textLight};
            transition: border-color 0.15s ease;
            overflow: hidden;
            position: relative;
            z-index: 1;

            svg {
                fill: ${props => props.theme.color.text};
                width: 100%;
                height: 100%;
            }
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                content: "";
                width: 100%;
                height: 100%;
                background-color: ${props => props.theme.color.primary};
                z-index: -1;
                transition: all 0.15s ease;
                border-radius: 50%;
                opacity: 0;
            }
            &:hover {
                border-color: ${props => props.theme.color.text};

                &::after {
                    transform: translate(-50%, -50%) scale(1);
                    opacity: 1;
                }
            }
        }
    }
`

const links = [
    // https://simpleicons.org/
    /*
    {
        id: "facebook",
        name: "Facebook",
        svg:
            "m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z",
        viewbox: "0 0 24 24",
        url: "https://www.facebook.com",
    },
    {
        id: "email",
        name: "Email",
        svg:
            "M467,80.609H45c-24.813,0-45,20.187-45,45v260.782c0,24.813,20.187,45,45,45h422c24.813,0,45-20.187,45-45V125.609 C512,100.796,491.813,80.609,467,80.609z M461.127,110.609l-6.006,5.001L273.854,266.551c-10.346,8.614-25.364,8.614-35.708,0 L56.879,115.61l-6.006-5.001H461.127z M30,132.267L177.692,255.25L30,353.543V132.267z M467,401.391H45 c-7.248,0-13.31-5.168-14.699-12.011l171.445-114.101l17.204,14.326c10.734,8.938,23.893,13.407,37.051,13.407 c13.158,0,26.316-4.469,37.051-13.407l17.204-14.326l171.444,114.1C480.31,396.224,474.248,401.391,467,401.391z M482,353.543 l-147.692-98.292L482,132.267V353.543z",
        viewbox: "0 0 512 512",
        url: "https://www.wikipedia.com",
    },
    {
        id: "youtube",
        name: "YouTube",
        svg:
            "M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80 c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904 C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728 c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816 c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096 C512,181.088,504.704,138.592,490.24,113.92z M192,352V160l160,96L192,352z",
        viewbox: "0 0 512 512",
        url: "https://www.youtube.com",
    },
    */
]

const footer = () => {
    const footerLinks = links.map(link => (
        <a href={link.url} className="footer-link" key={link.id}>
            <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox={link.viewbox}
            >
                <path d={link.svg}></path>
            </svg>
        </a>
    ))
    return (
        <>
            <Divider />
            <StyledFooter>
                <Wrapper>
                    <div className="footer-layout">
                        <div className="footer-copyright">
                            &copy; Sparrow Piano &amp; Oboe Studio&nbsp;
                            {new Date().getFullYear()}
                        </div>
                        {links.length ? (
                            <div className="footer-links">{footerLinks}</div>
                        ) : (
                            ""
                        )}
                    </div>
                </Wrapper>
            </StyledFooter>
        </>
    )
}

export default footer
