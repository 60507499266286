import React, { useContext } from "react"
import styled from "styled-components"

import SectionHeader from "./section-header"
import Wrapper from "./wrapper"
import MediaSlider from "./media-slider"
import ScrollSpyContext from "../context/ScrollSpyContext"

const StyledMedia = styled.section`
    min-height: ${props => props.theme.sectionMinHeight};
    background-color: ${props => props.theme.color.background};
    display: flex;
    flex-direction: column;

    .media-coming-soon {
        text-align: center;
        padding: 5rem 0;
    }
`

const Media = () => {
    const { refs } = useContext(ScrollSpyContext)

    return (
        <StyledMedia id="media" ref={refs[2]}>
            <Wrapper flex={true}>
                <SectionHeader>Media</SectionHeader>
                {/* <div className="media-coming-soon">Coming Soon</div> */}
                <MediaSlider />
            </Wrapper>
        </StyledMedia>
    )
}

export default Media
