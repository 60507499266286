import React from "react"
import styled from "styled-components"

const StyledLogoUnderlay = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    svg {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: visible;
    }

    line {
        stroke: ${props => props.theme.color.primary};
        stroke-width: 10px;
    }
    .key-natural {
        opacity: 0.65;
    }
    .key-accidental {
    }

    &[data-animate] {
        .key-natural {
			opacity 0;
			animation: showNatural 1s;
			animation-fill-mode: forwards;
        }
        .key-accidental {
			opacity: 0;
			animation: showAccidental 1s;
			animation-fill-mode: forwards;
		}
		.key-1 {
			animation-delay: 2s;
		}
		.key-2 {
			animation-delay: 2.25s;
		}
		.key-3 {
			animation-delay: 2.5s;
		}
		.key-4 {
			animation-delay: 2.75s;
		}
		.key-5 {
			animation-delay: 3s;
		}
    }
    @keyframes showNatural {
        0% {
            transform: translate(-16px, 38px);
            opacity: 0;
        }
        100% {
            transform: translate(0, 0);
            opacity: 0.65;
        }
    }
    @keyframes showAccidental {
        0% {
            transform: translate(16px, -38px);
            opacity: 0;
        }
        100% {
            transform: translate(0, 0);
            opacity: 1;
        }
    }
`

const LogoUnderlay = props => {
    const { animate } = props

    return (
        <StyledLogoUnderlay data-animate={animate ? "" : null}>
            <svg viewBox="0 0 100 100">
                <line
                    className="key-natural key-1"
                    x1="5"
                    y1="100"
                    x2="35"
                    y2="30"
                />
                <line
                    className="key-accidental key-2"
                    x1="63"
                    y1="0"
                    x2="33"
                    y2="70"
                />
                <line
                    className="key-natural key-3"
                    x1="35"
                    y1="100"
                    x2="65"
                    y2="30"
                />
                <line
                    className="key-accidental key-4"
                    x1="93"
                    y1="0"
                    x2="63"
                    y2="70"
                />
                <line
                    className="key-natural key-5"
                    x1="65"
                    y1="100"
                    x2="95"
                    y2="30"
                />
            </svg>
        </StyledLogoUnderlay>
    )
}

export default LogoUnderlay
