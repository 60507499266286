import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Testimonial from "./testimonial"
import Indicators from "./indicators"

import ActiveTestimonialContext from "../context/ActiveTestimonialContext"

// import useSlider from "../hooks/useSlider"

const StyledTestimonials = styled.div`
    position: relative;

    .testimonial-scroll {
        position: relative;
    }
`

const Testimonials = () => {
    const testimonials = [
        {
            quote:
                "We love Mrs. Sparrow! Our experience in her piano studio has contributed greatly to my children’s love of music. My children have progressed and improved so much from year to year. They enjoy the songs they play and look forward to their lessons each week. We couldn’t imagine going to any other teacher!",
            author: "Meghan Frost",
        },
        {
            quote:
                "After starting lessons with Candace, our daughters have progressed immensely in their piano skills.  She teaches them in a way that motivates them and keeps them interested.  She is awesome and the girls love working with her.",
            author: "Donna Nguyen",
        },
        {
            quote:
                "I am so grateful to have found Mrs. Sparrow to teach my daughters how to play piano!   They both really enjoy taking lessons with her and I appreciate her patience and sweet attitude.  She has also been so easy to work with and willing to accomodate our busy schedule of activities.",
            author: "Sandy L.",
        },
        {
            quote:
                "Mrs. Sparrow has been teaching my girls, Zara and Diya piano for a year and a half. They have learned so much in a short period of time and are progressing at a great pace. Mrs.Sparrow’s calm demeanor and caring nature is exactly what my girls need to excel. She encourages them and provides them with support that is unique to each of their needs. As a parent I love her work ethic and how organized the classes are. We are so thankful for having such a wonderful teacher and cannot recommend her enough.",
            author: "Shehnaz S.",
        },
    ]

    //  set longest testimonial as the first (so container is large enough)
    testimonials.sort((a, b) => b.quote.length - a.quote.length)

    const [activeSlide, setActiveSlide] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveSlide(
                activeSlide !== testimonials.length - 1 ? activeSlide + 1 : 0
            )
        }, 12250)

        return () => clearInterval(interval)
    }, [activeSlide, testimonials.length])

    return (
        <ActiveTestimonialContext.Provider
            value={{ activeSlide, setActiveSlide }}
        >
            <StyledTestimonials>
                <div className="testimonial-scroll">
                    {testimonials.map((testimonial, index) => {
                        return (
                            <Testimonial
                                key={index}
                                index={index}
                                quote={testimonial.quote}
                                author={testimonial.author}
                            />
                        )
                    })}
                </div>
                <Indicators
                    slides={testimonials}
                    activeSlide={activeSlide}
                    context={ActiveTestimonialContext}
                />
            </StyledTestimonials>
        </ActiveTestimonialContext.Provider>
    )
}

export default Testimonials
