import React, { useContext } from "react"
import styled from "styled-components"

import SectionHeader from "./section-header"
import Wrapper from "./wrapper"
import ContactForm from "./contact-form"
import ScrollSpyContext from "../context/ScrollSpyContext"

const StyledContact = styled.section`
    min-height: ${props => props.theme.sectionMinHeight};
    background-color: ${props => props.theme.color.background};
    padding-bottom: 6rem;

    p {
        text-align: center;
        line-height: 1.5;
        font-size: 1.125rem;
        margin: 0;
        padding-bottom: 1rem;
    }
    p:last-of-type {
        padding-bottom: 3rem;
    }
`

const Contact = () => {
    const { refs } = useContext(ScrollSpyContext)

    return (
        <StyledContact id="contact" ref={refs[3]}>
            <Wrapper>
                <SectionHeader>Contact</SectionHeader>
                <p>Questions - or ready to sign up?</p>
                <p>Please fill out the form below.</p>
                <ContactForm />
            </Wrapper>
        </StyledContact>
    )
}

export default Contact
