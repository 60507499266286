import React from "react"
import styled from "styled-components"
import Indicator from "./indicator"

const StyledIndicators = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem 2rem;
`

const Indicators = props => {
    const { slides, context, activeSlide, setActiveSlide } = props
    return (
        <StyledIndicators>
            {slides.map((slide, index) => (
                <Indicator
                    key={index}
                    id={index}
                    context={context}
                    activeSlide={activeSlide}
                    setActiveSlide={setActiveSlide}
                />
            ))}
        </StyledIndicators>
    )
}

export default Indicators
