import React from "react"
import styled from "styled-components"

import Wrapper from "./wrapper"

const StyledDivider = styled.div`
    display: flex;
    background-color: ${props => props.theme.color.background};

    hr {
        margin: 1rem 0;
        border: none;
        border-top: 1px solid ${props => props.theme.color.textExtraLight};
    }
`

const Divider = () => {
    return (
        <StyledDivider>
            <Wrapper>
                <hr />
            </Wrapper>
        </StyledDivider>
    )
}

export default Divider
