import React from "react"
import styled from "styled-components"

const StyledSectionSubHeader = styled.h3`
  padding: 0 0 2rem;
  margin: 0;
  line-height: 1;
  font-size: 2.5rem;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  text-align: center;
`

const SectionSubHeader = ({ children }) => (
  <StyledSectionSubHeader>{children}</StyledSectionSubHeader>
)

export default SectionSubHeader
