import React, { useContext } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import styled from "styled-components"

import useScrolled from "../hooks/useScrolled"
import useWindowSize from "../hooks/useWindowSize"

import Wrapper from "./wrapper"
import NavigationLogo from "./navigation-logo"
import ScrollSpyContext from "../context/ScrollSpyContext"

const StyledNavigation = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: transparent;
    height: ${props => props.theme.navHeight};
    transition: all 0.5s ease;
    z-index: 10;

    .nav-layout {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: ${props => props.theme.navHeight};
    }
    ul {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
    }
    li {
        margin-left: 1.5rem;
    }
    a {
        text-decoration: none;
        color: white;
        position: relative;
        transition: all 0.5s ease;
    }
    ul {
        a {
            &:after {
                position: absolute;
                content: "";
                height: 2px;
                width: 100%;
                bottom: -2px;
                left: 0;
                background-color: ${props => props.theme.color.primary};
                transform: scaleX(0);
                transition: all 0.15s ease;
            }
            // &:hover,
            &.active {
                &:after {
                    transform: scaleX(1);
                }
            }
            @media (hover: hover) {
                &:hover {
                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
    &[data-scrolled] {
        background: rgba(255, 255, 255, 0.9);

        a {
            color: ${props => props.theme.color.text};
        }
    }
`

const Navigation = () => {
    const { activeSection } = useContext(ScrollSpyContext)
    const [isScrolled] = useScrolled(50)
    const windowSize = useWindowSize()

    return (
        <StyledNavigation data-scrolled={isScrolled ? "" : null}>
            <Wrapper>
                <div className="nav-layout">
                    <NavigationLogo />
                    <ul>
                        <li>
                            <AnchorLink to="/#about" className={activeSection === 1 ? 'active' : ''}>About</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink to="/#media" className={activeSection === 2 ? 'active' : ''}>Media</AnchorLink>
                        </li>
                        <li>
                            <AnchorLink to="/#contact" className={activeSection === 3 ? 'active' : ''}>Contact</AnchorLink>
                        </li>
                    </ul>
                </div>
            </Wrapper>
        </StyledNavigation>
    )
}

export default Navigation
