import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Indicators from "./indicators"
import MediaImage from "./media-image"

import ActiveMediaContext from "../context/ActiveMediaContext"

const StyledMediaSlider = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .media-scroll {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: 1;
    }
`

const MediaSlider = () => {
    const data = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                    relativeDirectory: {eq: "media"}
                }
            ) {
                edges {
                    node {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `)

    const [activeMedia, setActiveMedia] = useState(0)
    const media = data.allFile.edges;

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveMedia(
                activeMedia !== data.allFile.edges.length - 1 ? activeMedia + 1 : 0
            )
        }, 12250)

        return () => clearInterval(interval)
    }, [activeMedia, data.allFile.edges.length])

    return (
        <ActiveMediaContext.Provider value={{ activeMedia, setActiveMedia }}>
            <StyledMediaSlider>
                <div className="media-scroll">
                    {media.map(({node}, index) => {
                        // console.log(node.childImageSharp.fluid.aspectRatio);
                        // return <Img className="test-image-style" key={index} fluid={node.childImageSharp.fluid} />
                        return (
                            <MediaImage
                                key={index}
                                index={index}
                                fluid={node.childImageSharp.fluid}
                            />
                        )
                    })}
                </div>
                <Indicators slides={media} activeSlide={activeMedia} setActiveSlide={setActiveMedia} context={ActiveMediaContext} />
            </StyledMediaSlider>
        </ActiveMediaContext.Provider>
    )
}

export default MediaSlider
