import React from "react"
import styled from "styled-components"

import useInputState from "../hooks/useInputState"

const StyledFormField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  label {
    transition: all 0.15s ease;
    position: absolute;
    top: 0.5rem;
	left: 0.5rem;
	cursor: text;
	opacity: 0.5;
  }
  input,
  textarea {
    width: 100%;
    transition: all 0.15s ease;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    outline: none;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.025);

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
  textarea {
	  resize: none;
	  margin-bottom: 2rem;
  }
  &[data-focus],
  &[data-filled] {
    label {
	  transform: scale(0.825) translate(-4px, -2rem);
	  cursor: default;
	  opacity: 1;
    }
    input,
    textarea {
		// border: 1px solid ${props => props.theme.color.primary}
	}
  &[data-focus] {
    label {
    }
    input,
    textarea {
		border: 1px solid ${props => props.theme.color.primary}
	}
  }
`

const FormField = props => {
  const { name, label, fieldType } = props
  const [
    value,
    handleChange,
    handleFocus,
    handleBlur,
    isFocused,
    isFilled,
  ] = useInputState("")

  return (
    <StyledFormField
      data-focus={isFocused ? "" : null}
      data-filled={isFilled ? "" : null}
    >
      <label htmlFor={name}>{label}</label>
      {fieldType === "text" ? (
        <input
          type="text"
          name={name}
          id={name}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          autoComplete="off"
        />
      ) : (
        <textarea
		  name={name}
		  id={name}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          autoComplete="off"
          rows={4}
        />
      )}
    </StyledFormField>
  )
}

FormField.defaultProps = {
  fieldType: "text",
}

export default FormField
