import React from "react"
import styled from "styled-components"

const StyledBreakDot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: ${props => props.theme.color.background};
    padding: 2rem 0;

    div.break-dot {
        content: '';
        width: .5rem;
        height: .5rem;
        background-color: ${props => props.theme.color.primary};
        border-radius: 50%;
    }
`

const BreakDot = () => {
    return (
        <StyledBreakDot>
            <div className="break-dot" />
        </StyledBreakDot>
    )
}

export default BreakDot
