import React from "react"
import styled from "styled-components"

import Wrapper from "./wrapper"
import LogoUnderlay from "./logo-underlay"

import "@fontsource/playfair-display"

const StyledHero = styled.section`
    padding-top: ${props => props.theme.navHeight};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    height: 100vh;
    background: linear-gradient(
        to top,
        ${props => props.theme.color.background},
        rgba(255, 255, 255, 0) 70%
    );
    animation: fadeIn 4s;

    .flex-layout {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 300px;

        @media screen and (max-width: ${props => props.theme.breakPoints.med}) {
            margin: 0 auto;
        }
    }
    .title {
        width: 100%;
        padding: 1rem 0;
        position: relative;

        @media screen and (max-width: ${props => props.theme.breakPoints.med}) {
            text-align: center;
        }
    }
    h1 {
        margin: 0;
        line-height: 1;
        font-size: 5rem;
        font-weight: 400;
        font: 400 5rem "Playfair Display", serif;
        position: relative;
        text-shadow: 1px 1px rgba(255, 255, 255, 0.65);
        cursor: default;
        
        @media screen and (max-width: ${props => props.theme.breakPoints.med}) {
            font-size: calc(3.5rem + 24 * ((100vw - 320px) / (700 - 320)));
        }
    }
    p {
        font-size: 1.5rem;
        margin: 0 0;
        position: relative;

        @media screen and (max-width: ${props => props.theme.breakPoints.med}) {
            font-size: calc(1rem + 8 * ((100vw - 320px) / (700 - 320)));
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`

const Hero = () => {
    return (
        <StyledHero id="home">
            <Wrapper>
                <div className="flex-layout">
                    <div className="title">
                        <LogoUnderlay animate={true} />
                        <h1>Sparrow</h1>
                        <p>Piano &amp; Oboe Studio</p>
                    </div>
                </div>
            </Wrapper>
        </StyledHero>
    )
}

export default Hero
