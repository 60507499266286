import { useState, useEffect } from "react"

const useScrolled = (scrollPoint = 50) => {
    const [isScrolled, setIsScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY > scrollPoint
            if (scrolled !== isScrolled) setIsScrolled(!isScrolled)
        }

        document.addEventListener("scroll", handleScroll, { passive: true })

        return () => {
            // clean up event handler when component unmounts
            document.removeEventListener("scroll", handleScroll)
        }
    }, [isScrolled, scrollPoint])

    return [isScrolled]
}

export default useScrolled
