import React from "react"
import styled from "styled-components"

const StyledFormSubmit = styled.button`
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: ${props => props.theme.color.primary};
  color: white;
  font-size: 1rem;
  margin: 0 auto;
  padding: 0.75rem 1.5rem;
  display: flex;
  transition: background-color 0.15s ease;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.color.primaryHover};
  }
  &:active {
    background-color: ${props => props.theme.color.primaryHover};
  }
`

const FormSubmit = props => {
  const { text } = props
  return <StyledFormSubmit type="submit">{text}</StyledFormSubmit>
}

export default FormSubmit
