import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 1rem;

  &.flex-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`

const Wrapper = ({ flex, children }) => <StyledWrapper className={flex && 'flex-section'}>{children}</StyledWrapper>

export default Wrapper
