import React, { useContext } from "react"
import styled from "styled-components"
// import ActiveTestimonialContext from "../context/ActiveTestimonialContext"

const StyledIndicator = styled.div`
    width: 2rem;
    height: 0.25rem;
    content: "";
    background-color: ${props => props.theme.color.textExtraLight};
    margin: 0 0.25rem;
    transition: all 0.15s ease;

    &[data-active] {
        background-color: ${props => props.theme.color.primary};
    }
    &:hover {
        transform: scale(1.15);
        cursor: pointer;
    }
`

const Indicator = props => {
    const { id, context, activeSlide, setActiveSlide } = props
    // const { activeSlide, setActiveSlide } = useContext(context)
    return (
        <StyledIndicator
            data-active={id === activeSlide ? "" : null}
            onClick={() => setActiveSlide(id)}
        />
    )
}

export default Indicator
