import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

const StyledAnimateOnIntersection = styled.div`
    opacity: 0;
    transform: translateY(50px);
    visibility: hidden;
    transition: all 1s ease;
    will-change: opacity, visibility;

    &.is-visible {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
`

const AnimateOnIntersection = ({children}) => {
    const domRef = useRef()
    const [isVisible, setVisible] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setVisible(true)
                observer.unobserve(domRef.current)
            }
        })
        
        observer.observe(domRef.current)

        return () => observer.unobserve(domRef.current)
    }, [])

    return (
        <StyledAnimateOnIntersection ref={domRef} className={isVisible ? " is-visible" : ""}>
            {children}
        </StyledAnimateOnIntersection>
    )
}

export default AnimateOnIntersection
