export const theme = {
    navHeight: "3rem",
    sectionMinHeight: "calc(100vh - 7rem)",

    color: {
        primary: "pink",
        primaryRGB: "255, 192, 203",
        primaryHover: "#e3acb5",
        text: "#333",
        black: "#333",
        textLight: "rgba(0, 0, 0, 0.5)",
        textExtraLight: "rgba(0, 0, 0, 0.25)",
		background: "#fff",
		white: "#fff",
    },

    breakPoints: {
        med: "700px",
    }
}
