import React from "react"
import styled from "styled-components"


const StyledVideoOverlay = styled.div``

const VideoOverlay = props => {
    return (
        <div>
            
        </div>
    )
}

export default VideoOverlay;