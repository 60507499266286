import React, { useContext } from "react"
import styled from "styled-components"
import ScrollSpyContext from "../context/ScrollSpyContext"

import Hero from "./hero"
import Navigation from "./navigation"

const StyledHeader = styled.header``

const Header = () => {
    const { refs } = useContext(ScrollSpyContext)
    return (
        <StyledHeader ref={refs[0]}>
            <Navigation />
            <Hero />
        </StyledHeader>
    )
}

export default Header
