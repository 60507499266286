import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

const Background = () => {
    const data = useStaticQuery(
        graphql`
            query {
                desktop: file(relativePath: { eq: "images/piano.jpg" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `
    )
    const imageData = data.desktop.childImageSharp.fluid

    return (
        <StyledBackgroundImageWrapper>
            <BackgroundImage Tag="div" fluid={imageData} />
        </StyledBackgroundImageWrapper>
    )
}

const StyledBackgroundImageWrapper = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
	z-index: -1;
	
	> div {
		width: 100%;
		height: 100%;
	}
`

const StyledBackgroundImage = styled(Background)`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    // z-index: -1;
`

export default StyledBackgroundImage