import React from "react"
import styled from "styled-components"
import LogoUnderlay from "./logo-underlay"

const StyledAllenMap = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;

    .map-wrap {
        width: 100%;
        max-width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        svg.map {
            width: 100%;
            overflow: visible;

            line {
                stroke: ${props => props.theme.color.text};
                stroke-width: 1px;
                opacity: 0.65;
            }
        }
    
        .map-logo {
            position: absolute;
            width: 15%;
            min-width: 50px;
            top: 35%;
            left: 50%;
        }
        
        .map-road-text {
            font-size: 1rem;
            background-color: white;
            padding: .1rem .25rem;
            position: absolute;
            transform: translate(-50%, -50%);
        }
        .map-road-text--75 {
            top: 56%;
            left: 63%;
        }
        .map-road-text--121 {
            top: 20%;
            left: 45%;
        }
        .map-road-text--161 {
            top: 94%;
            left: 32%;
        }
    }
    
`

const AllenMap = () => {
    return (
        <StyledAllenMap>
            <div className="map-wrap">
                <svg className="map" viewBox="0 0 100 100">
                    <line // 75
                        x1="50"
                        y1="100"
                        x2="80"
                        y2="0"
                    />
                    <line // george bush
                        x1="0"
                        y1="90"
                        x2="75"
                        y2="100"
                    />
                    <line // 121
                        x1="0"
                        y1="40"
                        x2="90"
                        y2="0"
                    />
                </svg>
                <div className="map-logo">
                    <LogoUnderlay />
                </div>
                <span className="map-road-text map-road-text--75">75</span>
                <span className="map-road-text map-road-text--121">121</span>
                <span className="map-road-text map-road-text--161">161</span>
            </div>
        </StyledAllenMap>
    )
}

export default AllenMap
