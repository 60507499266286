import React from "react"
import styled from "styled-components"

const StyledSectionHeader = styled.h2`
    padding: 2rem 0;
    margin: 0;
    line-height: 1;
    font-weight: 400;
    font-family: "Playfair Display", serif;
    font-size: 4rem;
    text-align: center;

    @media screen and (max-width: ${props => props.theme.breakPoints.med}) {
		font-size: calc(3rem + ((16 * 4) - (16 * 3)) * ((100vw - 320px) / (700 - 320)));
    }
`

const SectionHeader = ({ children }) => (
    <StyledSectionHeader>{children}</StyledSectionHeader>
)

export default SectionHeader
