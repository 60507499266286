import React, { useRef } from "react"

import Layout from "../components/layout"
import About from "../components/about"
import Media from "../components/media"
import Contact from "../components/contact"
import Divider from "../components/divider"
import SEO from "../components/seo"

import useScrollSpy from "react-use-scrollspy"
import ScrollSpyContext from "../context/ScrollSpyContext"

const IndexPage = () => {
    // const [activeSection, setActiveSection] = useState(null)
    const refs = [useRef(), useRef(), useRef(), useRef()]

    // setActiveSection(
    //     useScrollSpy({
    //         sectionElementRefs: refs,
    //         offsetPx: -80,
    //     })
    // )

    const activeSection = useScrollSpy({
        sectionElementRefs: refs,
        offsetPx: -200,
    })

    // setActiveSection(newActiveSection)

    return (
        <ScrollSpyContext.Provider
            value={{ refs: refs, activeSection: activeSection }}
        >
            <SEO />
            <Layout>
                <Divider />
                <About />
                <Divider />
                <Media />
                <Divider />
                <Contact />
            </Layout>
        </ScrollSpyContext.Provider>
    )
}

export default IndexPage
