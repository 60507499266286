import React, { useContext } from "react"
import styled from "styled-components"
import ActiveMediaContext from "../context/ActiveMediaContext"
import Img from "gatsby-image"
import VideoOverlay from "./video-overlay"

const StyledMedia = styled.div`
    position: absolute !important;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.25s ease;
    pointer-events: none;

    &[data-active] {
        pointer-events: auto;
        opacity: 1;
        transition-delay: 0.25s;
        z-index: 2;
    }

    .media-image {
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        > img {
            display: none; // a;ready fading in image, don't bother showing the preview
        }

        picture {
            width: 100%;
            height: 100%;
        }
        img {
            width: unset !important;
            height: unset !important;
            max-height: 100% !important;
            max-width: 100% !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%);
        }
    }
`

const Testimonial = props => {
    const { index, quote, author, fluid } = props
    const { activeMedia } = useContext(ActiveMediaContext)
    const src = props.fluid.src

    // is video if file starts with 'yt-'
    let isVideo =
        src.lastIndexOf("/") !== -1 &&
        src.slice(src.lastIndexOf("/") - src.length + 1).startsWith("yt-")
    // console.log(src.slice((src.lastIndexOf('/') - src.length + 4), (src.lastIndexOf('.') - src.length)));

    let link = '';
    if (isVideo) {
        link = `https://youtube.com/shorts/${src.slice(
            src.lastIndexOf("/") - src.length + 4,
            src.lastIndexOf(".") - src.length
        )}`
    }

    // https://youtube.com/shorts/

    return (
        <StyledMedia
            // data-testimonial={index}
            data-active={index === activeMedia ? "" : null}
        >
            <Img className="media-image" fluid={props.fluid} />
            {isVideo && <VideoOverlay link={link} />}
        </StyledMedia>
    )
}

export default Testimonial
