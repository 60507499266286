import React, { useContext } from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import SectionHeader from "./section-header"
import SectionSubHeader from "./section-sub-header"
import AboutImage from "./about-image"
import Wrapper from "./wrapper"
import BreakDot from "./break-dot"
import Testimonials from "./testimonials"
import AllenMap from "./allen-map"
import AnimateOnIntersection from "./animate-on-intersection"
import ScrollSpyContext from "../context/ScrollSpyContext"

const StyledAbout = styled.section`
    min-height: ${props => props.theme.sectionMinHeight};
    padding-bottom: 4rem;
    background-color: ${props => props.theme.color.background};

    p {
        line-height: 1.5;
        font-size: 1.125rem;
        margin: 0;
        padding-bottom: 1rem;

        &.align-center {
            text-align: center;
        }
    }
    .anchor-link {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 2px;
            background-color: ${props => props.theme.color.primary};
            z-index: -1;
            transition: all 0.15s ease;
        }
        &:hover {
            &::before {
                opacity: 0.5;
                height: 8px;
            }
        }
    }
`

const About = () => {
    const { refs } = useContext(ScrollSpyContext)
    const getAge = birthday => {
        const ageDate = new Date(Date.now() - birthday)
        return Math.abs(ageDate.getUTCFullYear() - 1970)
    }

    const ages = {
        aubrey: getAge(new Date(2013, 1, 25)),
        emily: getAge(new Date(2015, 10, 7)),
        charlotte: getAge(new Date(2017, 8, 13)),
        caroline: getAge(new Date(2020, 0, 29)),
    }

    const about = [
        "Candace grew up in Allen and began her love of music at a very young age. She began playing piano at age six and oboe at age twelve. Candace received her bachelors degree from SMU in music education and oboe performance. During her time at SMU she studied with Dallas Symphony’s principal oboist as well as distinguished piano faculty. Candace then received her masters degree from UNT in educational administration. She taught elementary music in Allen ISD followed by many years as a band director in Frisco ISD. Candace has been staying home to raise her kids and private teaching piano and oboe full time now for eight years.",
        `Candace and her husband, Bryan, have been married now for ten years and live in Allen. Bryan is a high school teacher in the area. They have four beautiful daughters, ages ${ages.aubrey}, ${ages.emily}, ${ages.charlotte}, and ${ages.caroline} that keep them very busy!`,
        "Candace is experienced in teaching kids of all ages and levels. Her goal is to meet the unique needs and interests of each student and to ultimately instill a love for music.",
    ]

    return (
        <StyledAbout id="about" ref={refs[1]}>
            <Wrapper>
                <SectionHeader>About</SectionHeader>
                <AnimateOnIntersection>
                    <AboutImage />
                </AnimateOnIntersection>
                <AnimateOnIntersection>
                    {about.map((text, index) => {
                        return <p key={index}>{text}</p>
                    })}
                </AnimateOnIntersection>
                <BreakDot />
                <AnimateOnIntersection>
                    <SectionSubHeader>Location</SectionSubHeader>
                    <p className="align-center">
                        Home studio in <strong>Allen, TX</strong>
                    </p>
                    <AllenMap />
                    <p className="align-center">
                        (address available upon{" "}
                        <AnchorLink className="anchor-link" to="/#contact">
                            request
                        </AnchorLink>
                        )
                    </p>
                </AnimateOnIntersection>
                <BreakDot />
                <AnimateOnIntersection>
                    <SectionSubHeader>Pricing</SectionSubHeader>
                    <p className="align-center">$36 / 30 minute lesson</p>
                </AnimateOnIntersection>
                <BreakDot />
                <Testimonials />
            </Wrapper>
        </StyledAbout>
    )
}

export default About
