import React from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import useScrolled from "../hooks/useScrolled"
import useWindowSize from "../hooks/useWindowSize"

import LogoUnderlay from "./logo-underlay"

import "@fontsource/playfair-display"

const StyledLogo = styled.div`
    font: 400 1.5rem "Playfair Display", serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease;
    width: 1.5rem;

    .logo-link {
        z-index: 1;
        padding: 0 10px;
    }
    &[data-logo-scrolled] {
        opacity: 1;
        pointer-events: auto;
        transition: opacity 1s ease;
    }
`

const Logo = () => {
    const { height } = useWindowSize()
    const [logoScrolled] = useScrolled(height * .6)

    return (
        <StyledLogo data-logo-scrolled={logoScrolled ? "" : null}>
            <AnchorLink to="/#home" className="logo-link">
                S
            </AnchorLink>
            <LogoUnderlay />
        </StyledLogo>
    )
}

export default Logo
