import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const StyledAboutImage = styled.div`
    margin: 0 auto 2rem;
    width: 300px;
    height: auto;
	max-width: 100%;
    // overflow: hidden;
    box-shadow: 0 0 0 0 ${props => props.theme.color.primary};
	animation: about-image-shadow 8s infinite;

    img {
        width: 100%;
	}
	
	@keyframes about-image-shadow {
		0% {
			box-shadow: .5rem .5rem 0 0 ${props => props.theme.color.primary};
		}
		50% {
			box-shadow: -.5rem -.5rem 0 0 ${props => props.theme.color.primary};
		}
		100% {
			box-shadow: .5rem .5rem 0 0 ${props => props.theme.color.primary};
		}
	}
`
const StyledAnimatedShadow = styled.div`
    height: 100%;
    overflow: hidden;
    box-shadow: 0 0 0 0 rgba(${props => props.theme.color.primaryRGB}, 0.5);
	animation: about-image-faded-shadow 9s infinite;

	@keyframes about-image-faded-shadow {
		0% {
			box-shadow: -.5rem .5rem 0 0 rgba(${props => props.theme.color.primaryRGB}, 0.5);
		}
		25% {
			box-shadow: 0 0 0 -1rem rgba(${props => props.theme.color.primaryRGB}, 0.5);
		}
		50% {
			box-shadow: .5rem -.5rem 0 0 rgba(${props => props.theme.color.primaryRGB}, 0.5);
		}
		75% {
			box-shadow: 0 0 0 -1rem rgba(${props => props.theme.color.primaryRGB}, 0.5);
		}
		100% {
			box-shadow: -.5rem .5rem 0 0 rgba(${props => props.theme.color.primaryRGB}, 0.5);
		}
	}
`

const AboutImage = () => {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "images/candace.jpg" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

	console.log(data)

    return (
        <StyledAboutImage>
			<StyledAnimatedShadow>
            	<Img fluid={data.file.childImageSharp.fluid} alt={data.alt} />
			</StyledAnimatedShadow>
        </StyledAboutImage>
    )
}

export default AboutImage
