import React from "react"
import styled from "styled-components"

const StyledBlockquoteIcon = styled.div`
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    left: 0;
    z-index: 1;
    user-select: none;

    svg * {
        fill: rgba(${props => props.theme.color.primaryRGB}, 0.35);
    }
`

const BlockquoteIcon = () => {
    return (
        <StyledBlockquoteIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g>
                    <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z" />
                </g>
            </svg>
        </StyledBlockquoteIcon>
    )
}

export default BlockquoteIcon
