import { useState } from "react"

export default initialValue => {
  const [value, setValue] = useState(initialValue)
  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const handleChange = e => {
	  setValue(e.target.value)
	  setIsFilled(e.target.value !== '')
  }

  const handleFocus = () => setIsFocused(true)

  const handleBlur = () => setIsFocused(false)

  return [value, handleChange, handleFocus, handleBlur, isFocused, isFilled]
}
